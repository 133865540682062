/**
 * 物流跳链
 * @param {物流id} id
 */
export const toexpressname = (id) => {
  window.open(
    'https://www.dhl.co.uk/en/express/tracking.shtml?AWB=' +
    id +
    '&brand=DHL'
  )
}
