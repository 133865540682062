<template>
  <q-dialog v-model="show" @hide="exchangeHide">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ $t("orders.before_exchange") }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <div class="exchange-goods-box">
          <div class="left">
            <q-img :src="exchangeGoodsInfo.photo" :ratio="6 / 8" />
          </div>
          <div class="right">
            <p class="blod">{{ exchangeGoodsInfo.brand }}</p>
            <p>
              {{
                $i18n.locale == "en"
                  ? exchangeGoodsInfo.titleen
                  : exchangeGoodsInfo.title
              }}
            </p>
            <p>
              {{ $t("orders.color")
              }}{{
                $i18n.locale == "en"
                  ? exchangeGoodsInfo.coloren
                  : exchangeGoodsInfo.color
              }}
            </p>
            <p v-if="exchangeGoodsInfo.size != '01'">
              {{ $t("orders.size") }}{{ exchangeGoodsInfo.size }}
            </p>
            <p>{{ $t("orders.price") }}{{ exchangeGoodsInfo.realamount }}</p>
            <p>{{ $t("orders.g_code") }}{{ exchangeGoodsInfo.code }}</p>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    exchangeGoodsInfo: {
      type: Object,
      default: () => {}
    },
    exchangeShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false
    }
  },
  watch: {
    exchangeShow (val) {
      this.show = val
    }
  },
  methods: {
    exchangeHide () {
      this.$emit('hideHandle', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.exchange-goods-box {
  display: flex;
  align-items: flex-end;
  .left {
    width: 150px;
    margin-right: 15px;
    border: 1px solid #a8a8a8;
  }
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
