<template>
  <div class="pucontainer">
    <div class="topTitle">
      <h5>{{ $t("orders.select_return") }}</h5>
    </div>
    <!-- 退换货品 -->
    <div class="orderlist">
      <div class="ol_item" v-for="(item, i) in list" :key="i">
        <div class="topTab puitem">
          <div>{{ $t("orders.date") }}</div>
          <div>{{ $t("orders.order_number") }}</div>
          <div>{{ $t("orders.shipping_address") }}</div>
          <div>{{ $t("orders.voucher") }}</div>
          <div>{{ $t("orders.s_pay") }}</div>
        </div>
        <div class="con puitem">
          <div>
            <p>{{ item.newdate }}</p>
            <p class="status blod">
              {{
                $i18n.locale == "en"
                ? item.status_contenten
                : item.status_content
              }}
            </p>
          </div>
          <div>{{ item.order_no }}</div>
          <div>
            <p>{{ $t("orders.name") }}{{ item.name }}</p>
            <p>{{ $t("orders.phone") }}{{ item.tel }}</p>
            <p>{{ $t("orders.email") }}{{ item.email }}</p>
            <p>
              {{ $t("orders.address") }}{{ item.province }}{{ item.city
              }}{{ item.area }}{{ item.address }}
            </p>
            <p v-if="item.zcode">
              {{ $t("orders.post_code") }}{{ item.zcode }}
            </p>
            <p class="look-wl" v-if="item.expressname" @click="logistics(item.expressname)">
              {{ $t("orders.look_wl") }}
            </p>
          </div>
          <div class="coupon">
            <template v-if="item.coupon || (item.coupon2 && item.iscoupon2 != '')">
              <p v-if="item.coupon">
                {{ $i18n.locale == "en" ? item.couponen : item.coupon }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount
                }}
              </p>
              <p v-if="item.coupon3">
                {{ $i18n.locale == "en" ? item.coupon3en : item.coupon3 }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount3
                }}
              </p>
              <p v-if="item.coupon2 && item.iscoupon2 != ''">
                {{ $i18n.locale == "en" ? item.coupon2en : item.coupon2 }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount2
                }}
              </p>
              <p v-if="item.discountamount !== '0.00'">
                {{ $t("orders.total_discount") }}{{ item.channel == 3 ? '￡' : '￥' }}{{ item.discountamount }}
              </p>
            </template>
            <template v-else>{{ $t("orders.not_used") }}</template>
          </div>
          <div>
            <div :style="item.refundamount != '0.00' || item.refundbalance != '0.00' ? 'color:#999' : ''">
              <p>
                {{ $t("orders.y_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.payableamount }}
              </p>
              <p style="margin-bottom: 15px;" v-if="item.refundamount != '0.00' || item.refundbalance != '0.00'">
                {{ $t('orders.change') }}：{{ item.channel == 3 ? '￡' : '￥' }}{{ item.payableamounta }}
              </p>
              <p v-if="item.coupon2 && item.iscoupon2 == ''">
                {{ $i18n.locale == "en" ? item.coupon2en : item.coupon2 }}
              </p>
              <p>{{ $t("orders.s_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.realamount }}</p>
              <p v-if="item.balances != '0.00' || item.refundbalance != '0.00'">
                {{ $t("orders.b_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.balances }}
              </p>
            </div>
            <p class="liner-dashed" v-if="item.refundamount != '0.00' || item.refundbalance != '0.00'"></p>
            <p v-if="item.refundamount != '0.00'">
              {{ $t("orders.refund") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.refundamount }}
            </p>
            <p v-if="item.refundbalance != '0.00'">
              {{ $t("orders.b_refund") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.refundbalance }}
            </p>
          </div>
        </div>
        <div class="detailList">
          <div class="puitem topTab2">
            <div>{{ $t("orders.return_title") }}</div>
            <div>{{ $t("orders.product_title") }}</div>
            <div></div>
          </div>
          <template v-for="(jtem, j) in item.list" :key="j">
            <div class="con puitem" v-if="jtem.applicationtype != 2 ||
              (jtem.applicationtype == 2 && jtem.order_status == 9)
              ">
              <div class="goods-status">
                <template v-if="jtem.order_status != 6 &&
                  jtem.order_status != 8 &&
                  jtem.order_status != 10
                  ">
                  <span></span>
                  <q-checkbox size="xs" :label="$t('orders.return_title')" v-model="jtem.returnSelect" />
                  <span></span>
                </template>
                <template v-else>
                  <template v-if="jtem.applicationtype != 0">
                    <template v-if="jtem.applicationtype == 1">
                      <template v-if="jtem.order_status == 8">
                        <span></span>
                        <span>{{ $t("orders.return_complete2") }}</span>
                        <span></span>
                      </template>
                      <template v-if="jtem.order_status == 9">
                        <span></span>
                        <span>{{ $t("orders.return_fail2") }}</span>
                        <span>{{ $t("orders.reason") }}:{{
                          $i18n.locale == "en"
                          ? jtem.auditnoteen
                          : jtem.auditnote
                        }}</span>
                      </template>
                      <template v-if="jtem.order_status == 10">
                        <span></span>
                        <span>{{ $t("orders.return_examine2") }}</span>
                        <span>{{ $t("orders.wait_customer_service") }}</span>
                      </template>
                    </template>
                    <template v-if="jtem.applicationtype == 2">
                      <template v-if="jtem.order_status == 8">
                        <span></span>
                        <span>{{ $t("orders.exchange_complete") }}</span>
                        <span></span>
                      </template>
                      <template v-if="jtem.order_status == 9">
                        <span></span>
                        <span>{{ $t("orders.exchange_fail") }}</span>
                        <span>
                          {{ $t("orders.reason") }}:{{
                            $i18n.locale == "en"
                            ? jtem.auditnoteen
                            : jtem.auditnote
                          }}
                        </span>
                      </template>
                      <template v-if="jtem.order_status == 10">
                        <span></span>
                        <span>{{ $t("orders.exchange_examine") }}</span>
                        <span>{{ $t("orders.wait_customer_service") }}</span>
                      </template>
                    </template>
                    <template v-if="jtem.applicationtype == 3">
                      <template v-if="jtem.order_status == 8">
                        <span></span>
                        <span>{{ $t("orders.return_complete") }}</span>
                        <span></span>
                      </template>
                      <template v-if="jtem.order_status == 9">
                        <span></span>
                        <span>{{ $t("orders.return_fail") }}</span>
                        <span>
                          {{ $t("orders.reason") }}:{{
                            $i18n.locale == "en"
                            ? jtem.auditnoteen
                            : jtem.auditnote
                          }}
                        </span>
                      </template>
                      <template v-if="jtem.order_status == 10">
                        <span></span>
                        <span>{{ $t("orders.return_examine") }}</span>
                        <span>{{ $t("orders.wait_customer_service") }}</span>
                      </template>
                    </template>
                  </template>
                  <template v-if="jtem.applicationtype == 0">
                    <template v-if="jtem.order_status == 6">
                      <span></span>
                      <span>{{ $t("orders.exchange_goods") }}</span>
                      <span></span>
                    </template>
                    <template v-else-if="jtem.order_status == 8">
                      <span></span>
                      <span>{{ $t("orders.exchange_complete") }}</span>
                      <span></span>
                    </template>
                    <template v-else-if="jtem.order_status == 10">
                      <span></span>
                      <span>{{ $t("orders.exchange_examine") }}</span>
                      <span>{{ $t("orders.wait_customer_service") }}</span>
                    </template>
                    <template v-else>
                      <span></span>
                      <span>{{ $t("orders.normal_goods") }}</span>
                      <span></span>
                    </template>
                  </template>
                </template>
              </div>
              <div class="goodsdiv">
                <div class="left">
                  <template v-if="jtem.sprices == '0.00' && jtem.stock == 0">
                    <q-img :src="jtem.photo" alt :ratio="6 / 8" @click="$q.notify($t('common.no_goods'))" />
                  </template>
                  <template v-else>
                    <div @click="goodsToUrl(jtem)">
                      <q-img :src="jtem.photo" alt :ratio="6 / 8" />
                    </div>
                  </template>
                </div>
                <div class="right">
                  <p class="brand blod">{{ jtem.brand }}</p>
                  <p class="name">
                    {{ $i18n.locale == "en" ? jtem.titleen : jtem.title }}
                  </p>
                  <p class="color">
                    {{ $t("orders.color")
                    }}{{ $i18n.locale == "en" ? jtem.coloren : jtem.color }}
                  </p>
                  <p class="size" v-if="jtem.size != '01'">
                    {{ $t("orders.size") }}{{ jtem.size }}
                  </p>
                  <p class="code">{{ $t("orders.g_code") }}{{ jtem.code }}</p>
                  <p class="look-goods" v-if="jtem.applicationtype == 0 &&
                    (jtem.order_status == 8 ||
                      jtem.order_status == 6 ||
                      jtem.order_status == 10)
                    " @click="exchangeClick(item, jtem)">
                    {{ $t("orders.look_before_exchange") }}
                  </p>
                </div>
              </div>
              <div>
                <div class="return-action" v-show="jtem.returnSelect">
                  <div class="action-box">
                    <div class="warp">
                      <p class="blod">{{ $t("orders.return_reason") }}</p>
                      <q-select filled v-model="jtem.reason" dense :options="reason" />
                    </div>
                    <div class="warp">
                      <p class="blod">{{ $t("orders.return_mode") }}</p>
                      <q-option-group size="xs" dense v-model="jtem.checked" :options="radioOptions" type="radio"
                        @update:model-value="radioChange(item, jtem)" />
                    </div>
                  </div>
                  <div class="action-box">
                    <template v-if="jtem.checked == 2">
                      <div class="warp">
                        <p class="blod">{{ $t("orders.color2") }}</p>
                        <q-select v-model="jtem.returnColor" dense filled :option-label="$i18n.locale == 'en' ? 'colorsen' : 'colors'
                          " :options="colorOptions" @update:model-value="colorChange(jtem)" />
                      </div>
                      <div class="warp" v-if="jtem.returnColor && jtem.returnSize.size != '01'">
                        <p class="blod">{{ $t("orders.size2") }}</p>
                        <q-select v-model="jtem.returnSize" dense :option-disable="(jtem) =>
                          jtem.number === '0' ? true : jtem.returnSize
                          " filled option-label="size" :options="sizeOptions" />
                      </div>
                    </template>
                  </div>
                  <div class="action-box">
                    <div class="warp">
                      <p class="blod">{{ $t("orders.is_received") }}</p>
                      <div class="received">
                        <q-radio dense size="xs" v-model="jtem.isReceived" val="已收到货"
                          :label="$t('orders.return_received')" v-if="item.status == 3 || item.status == 4" />
                        <q-radio dense size="xs" v-model="jtem.isReceived" val="未收到货"
                          :label="$t('orders.return_not_received')" v-if="item.status != 4" />
                      </div>
                    </div>
                    <div class="warp">
                      <p>{{ $t("orders.contact_your") }}</p>
                      <q-btn unelevated color="primary" :label="$t('orders.return_button')"
                        @click="postReturn(item, jtem)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <show-return :exchangeShow="exchangeShow" :exchangeGoodsInfo="exchangeGoodsInfo" @hideHandle="exchangeShow = false">
    </show-return>
  </div>
</template>

<script>
import { getOrder, usersaleApi } from '@/api/my'
import { toexpressname } from '@/plugins/logistics'
import showReturn from '@/components/lookReturnGoods'
export default {
  components: {
    showReturn
  },
  data() {
    return {
      list: [],
      reason: [
        this.$t('orders.wrong_size'),
        this.$t('orders.wrong_color'),
        this.$t('orders.dislike'),
        this.$t('orders.defects'),
        this.$t('orders.other')
      ],
      radioOptions: [
        { label: this.$t('orders.full_refund'), value: 1 },
        { label: this.$t('orders.change_color_size'), value: 2 }
      ],
      colorOptions: [],
      sizeOptions: [],
      exchangeShow: false,
      exchangeGoodsInfo: {}
    }
  },
  methods: {
    async getList() {
      const res = await getOrder({
        type: 0,
        status: 234,
        where: this.$route.query.id
      })
      if (res.status === 1) {
        if (res.list.length === 0) {
          this.$router.push('/my/returnGoodsList')
        } else {
          res.list.forEach((i) => {
            i.list.forEach((j) => {
              j.checked = 1
              j.reason = this.$t('orders.dislike')
            })
          })
        }
        this.list = res.list
      }
    },
    /* 获取商品尺码 */
    async getAttr(cguid, channel) {
      const res = await usersaleApi({
        action: 'size',
        commodityguid: cguid,
        channel: channel
      })
      const arr = res.salesspecifications
      this.colorOptions = arr
    },
    // 退换方式变化
    radioChange(item, jtem) {
      if (jtem.checked === 2) {
        this.getAttr(jtem.cguid, item.channel)
      }
    },
    /* 提交申请 */
    async postReturn(item, jtem) {
      if (jtem.checked === 2 && !jtem.returnSize) {
        this.$q.notify(this.$t('orders.p_select_color_size'))
        return
      }
      if (jtem.isReceived === undefined) {
        this.$q.notify(this.$t('orders.select_confirm_receipt'))
        return
      }
      if (jtem.isReceived === '未收到货' && item.status === 3) {
        this.$q.notify(this.$t('orders.delivered'))
        return
      }
      if (jtem.isReceived === '未收到货') {
        const res = await getOrder({
          type: 0,
          status: 234,
          where: this.$route.query.id
        })
        if (res.list[0].status !== item.status) {
          this.$q.notify(this.$t('orders.delivered'))
          item.status = res.list[0].status
          item.status_content = res.list[0].status_content
          return
        }
      }
      const obj = {
        salesspecifications_guid: ''
      }
      if (jtem.returnSize) {
        obj.salesspecifications_guid = jtem.returnSize.guid
      }
      const res = await getOrder({
        type: 8,
        mark: item.oguid,
        applicationreason: jtem.reason,
        applicationtype: jtem.checked,
        spguid: jtem.spguid,
        applicationreceipt: jtem.isReceived,
        channel: item.channel,
        ...obj
      })
      if (res.status === 1) {
        this.getList()
      }
      this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
    },
    // 颜色变化
    colorChange(jtem) {
      this.sizeOptions = jtem.returnColor.salesspecifications
      jtem.returnSize = jtem.returnColor.salesspecifications[0]
    },
    // 查看物流信息
    logistics(id) {
      toexpressname(id)
    },
    // 查看换货商品弹框
    exchangeClick(item, jtem) {
      const goods = item.list.find((val) => {
        if (val.applicationtype === '2') {
          return val.cguid === jtem.cguid
        }
      })
      this.exchangeGoodsInfo = goods
      this.exchangeShow = true
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import './orderScss.scss';

.topTitle {
  width: 100%;
  margin: 0px auto;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.orderlist {
  .ol_item {
    .detailList {
      &>.puitem>div:first-child {
        width: 200px;
        max-width: 200px;
      }

      .goodsdiv {
        width: 400px;
        max-width: 400px;
        border: none;
      }

      .return-action {
        border-left: 1px solid #a8a8a8 !important;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .action-box {
          width: 100%;
          max-width: 300px;
          padding: 0 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .warp {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }

          .received {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
